import styled from "styled-components"
import { fonts } from "../../../utils/styles"

export const StyledPostPreview = styled.div`
  span {
    font-family: ${fonts.nunito};
    small {
      font-size: 0.7rem;
    }
  }
  .preview-img {
    height: 10rem;
    border-radius: 16px;
  }
`
