import styled from "styled-components"

export const SignUpWrapper = styled.section`
  background-color: #f7f9fc;
  .container {
    max-width: 500px;
    .form-group {
      .form-control {
        &::placeholder {
          color: #9f9e9e;
        }
        + span {
          color: #da3939;
          top: 18px;
        }
        &:focus {
          + span {
            display: none;
          }
        }
      }
    }
    span {
      left: 110px;
    }
    .email-star {
      left: 70px;
    }
    small {
      color: #9f9e9e;
    }
  }
`
