import React, { useState } from "react"
import { SignUpWrapper } from "./SignUpForNewsLetter.styles"
import { Container, Form } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const SignUpForNewsLetter = () => {
  const [submitMessage, setSubmitMessage] = useState("");
  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key,data[key]);
    }
    return formData;
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        'first-name': form['first-name'].value,
        'last-name': form['last-name'].value,
        email: form.email.value,
        "got-ya": form["got-ya"].value
      })
    })
    .then(() => setSubmitMessage("Thank you for your subscription"))
    .catch((error) =>setSubmitMessage("Sorry! something went wrong!"))
  }
  return (
    <SignUpWrapper className="py-5 px-4 px-lg-0">
      <Container className="text-center py-5">
        <h2 className="pb-4">Sign up for the newsletter</h2>
        <Form
          className="text-center"
          name="subscribe-newsletter"
          method="POST"
          data-netlify="true"
          netlify-honeypot="got-ya"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="subscribe-newsletter" />
          <Form.Group className="position-relative py-2">
            <Form.Label hidden>First Name</Form.Label>
            <Form.Control
              type="text"
              name="first-name"
              placeholder="First Name *"
              className="rounded-0 p-4"
              required={true}
            />
            {/* <span className="position-absolute py-2">*</span> */}
          </Form.Group>
          <Form.Group
            className="position-relative"
            controlId="formBasicPassword"
          >
            <Form.Label hidden>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last-name"
              placeholder="Last Name *"
              className="rounded-0 p-4"
              required={true}
            />
            {/* <span className="position-absolute">*</span> */}
          </Form.Group>
          <Form.Group className="position-relative py-2">
            <Form.Label hidden>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email *"
              className="rounded-0 p-4"
              required={true}
            />
            {/* <span className="position-absolute email-star">*</span> */}
          </Form.Group>
          <label style={{ visibility: "hidden" }}>
            Don’t fill this out if you're human: <input name="got-ya" />
          </label>
          <p>{submitMessage}</p>
          <ApButton
            variant="primary"
            type="submit"
            className="rounded mb-4"
          >
            Subscribe
          </ApButton>
        </Form>
        <small>
          By submitting this form, you acknowledge that you have reviewed the
          terms of our{" "}
          <Link className="secondary-blue-2-text" to={LINKS.privacyPolicy}>
            Privacy Statement
          </Link>{" "}
          and consent to the use of data in accordance therewith.
        </small>
      </Container>
    </SignUpWrapper>
  )
}

export default SignUpForNewsLetter
