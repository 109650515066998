import { Button, Col, Container, Row } from "react-bootstrap"
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import { Link, graphql } from "gatsby"
import React, { useEffect, useState } from "react"

import ApButton from "../components/Global/Common/ApButton/ApButton"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FiLink } from "react-icons/fi"
import Image from "gatsby-image"
import Layout from "../components/Global/Layout/Layout"
import PostPreview from "../components/BlogPage/Tabs/PostPreview"
import SEO from "../components/Global/SEO/seo"
import SignUpForNewsLetter from "../components/BlogPage/SignUpForNewsLetter/SignUpForNewsLetter"
import { colors } from "../utils/styles"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from '@contentful/rich-text-types';
import styled from "styled-components"
import { navigateTo } from "../utils/hooks"
import { contentStyles } from "../components/Flexipage/Global/FlexipageStyles"

const ArticleHeader = styled.div`
  background: ${({ imageExists }) =>
    imageExists
      ? `linear-gradient(
    180deg,
    rgba(59, 92, 167, 1) 70%,
    rgba(255, 255, 255, 1) 30%
  )`
      : `linear-gradient(
    180deg,
    rgba(59, 92, 167, 1) 100%,
    rgba(255, 255, 255, 1) 30%
  )`};
  @media (max-width: 992px) {
    background: ${colors.secondaryBlue3};
  }
  padding-top: 88px;
  .title {
    color: #4cb9ef;
    font-weight: 800;
    @media (min-width: 992px) {
      font-size: 3rem;
    }
  }
  .category,
  .created-at {
    color: #55b9a3;
    font-weight: 600;
    font-size: 1.4rem;
  }
  .blog-header-image {
    @media (min-width: 992px) {
      border-radius: 16px;
    }
  }

  .header-author-details{
    display: flex;
    gap: 15px;
    align-items: center;

    .author-profile-image{
      width: 50px;
      border-radius: 50%;
    }  
  }

  
`
const ArticleBody = styled.article`
  ${contentStyles}
`

const AuthorDetails = styled.section`
  .author-details{
    background: linear-gradient(180deg,rgba(85,185,163,0.16) 0%,rgba(143,197,234,0.4) 100%);
    border-radius: 16px;
    display: grid;
    grid-template-columns: 150px 1fr;
    justify-content: center;
    align-items: center;
    gap: 25px;

    .author-profile-image{
      width: 150px;
      border-radius: 50%;
    }

    .author-name{
      font-size: 1.25em;
      line-height: 1.25em;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      text-align: center;
      
      .author-profile-image{
        margin: auto;
      }
    }
  }
`

const ShareBarStyles = styled.section`
  color: #144056;
  padding-top: 10px;
  padding-bottom: 20px;
  .share-bar-header {
    color: black;
    font-size: 0.95rem;
  }
  #copy-link-text {
    color: inherit;
  }
`
// const handleShareClick = (clickId) => {
//   const browserWindow = typeof window !== `undefined` ? window : null
//   browserWindow &&
//     browserWindow.dataLayer &&
//     browserWindow.dataLayer.push({ event: clickId })
//   console.log("clickId recorded: ", { event: clickId })
// }

const ShareBar = ({ pageUrl, title }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    const socialTags = ["linkedin", "twitter", "email", "copyLink"]
    const elementsToBeAddedWithId = ["svg", "rect", "path", "button", "a"]
    // fetch all elements with social Id share-button-${socialTag}
    const socialButtons = document.querySelectorAll(socialTags.map(st =>`#share-button-${st}`));
    // itereate over all social Buttons
    socialButtons.forEach((socialButton, buttonIndex) => {
      // fetch known element Tags
      elementsToBeAddedWithId.forEach(tagName => {
        const elements = socialButton.getElementsByTagName(tagName) || [];
        // set ids to them
        for (let element of elements) {
          element.setAttribute('id', `article-share-${socialTags[buttonIndex%socialTags.length]}`);
        }
      });
    })
  },[pageUrl]);
  return (
    <ShareBarStyles>
      <span className="share-bar-header px-2 ">SHARE ON SOCIAL MEDIA</span>
      <br />
      <LinkedinShareButton
        id="share-button-linkedin"
        className="share-buttons"
        url={pageUrl}
        title={title}
      // onClick={() => handleShareClick("article-share-linkedin")}
      >
        <LinkedinIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </LinkedinShareButton>
      <TwitterShareButton
        id="share-button-twitter"
        className="share-buttons"
        url={pageUrl}
        title={title}
      // onClick={() => handleShareClick("article-share-twitter")}
      >
        <TwitterIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </TwitterShareButton>
      <EmailShareButton
        id="share-button-email"
        className="share-buttons"
        url={pageUrl}
        subject={title}
      // onClick={() => handleShareClick("article-share-email")}
      >
        <EmailIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </EmailShareButton>
      <CopyToClipboard
        id="share-button-copyLink"
        className="share-buttons"
        text={pageUrl}
        style={{ color: "#090909" }}
        onCopy={() => setLinkCopied(true)}
      // onClick={() => handleShareClick("article-copyLink")}
      >
        <Button variant="link" id="copyLink-text">
          <FiLink />
          <span id="article-share-copyLink">&nbsp; {linkCopied ? "Link Copied" : "Copy Link"}</span>
        </Button>
      </CopyToClipboard>
      <br />
      <br />
    </ShareBarStyles>
  )
}

const BlogArticle = ({ data }) => {
  const [pageUrl, setPageUrl] = useState("")
  useEffect(() => {
    const pageUrl = window.location.href
    setPageUrl(pageUrl)
  }, [])
  const {
    contentfulBlogPost: {
      childContentfulBlogPostArticleRichTextNode: { json },
      title,
      category,
      publishedOn,
    },
    allContentfulBlogPost: { edges: latestPosts },
  } = data

  const renderOption = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <img
            src={`https:${node.data.target.fields.file.["en-US"].url}`}
            height={node.data.target.fields.file.["en-US"].details.image.height}
            width={node.data.target.fields.file.["en-US"].details.image.width}
          />
        )
      }
    }
  }

  const document = documentToReactComponents(json, renderOption)

  const showCategory = category[0].slug !== "internal"

  const imageData = data.contentfulBlogPost.previewImage
    ? data.contentfulBlogPost.previewImage.fluid
    : null;

    const imageDataAlt = data.contentfulBlogPost.previewImage && data.contentfulBlogPost.previewImage.title ? data.contentfulBlogPost.previewImage.title : "Article Lead Image";

    const authorImage = data.contentfulBlogPost.author && data.contentfulBlogPost.author.length > 0
    ? data.contentfulBlogPost.author[0].profilePicture
        ? data.contentfulBlogPost.author[0].profilePicture.fluid
        : null
    : null;

    const authorImageAlt = data.contentfulBlogPost.author && data.contentfulBlogPost.author.length > 0 && data.contentfulBlogPost.author[0].profilePicture.title ? data.contentfulBlogPost.author[0].profilePicture.title : 'Article Author';

    const authorName = data.contentfulBlogPost.author && data.contentfulBlogPost.author.length > 0
    ? data.contentfulBlogPost.author[0].name : null;
    const authorDescription = data.contentfulBlogPost.author && data.contentfulBlogPost.author.length > 0
    ? data.contentfulBlogPost.author[0].description : null;
    const authorEmail = data.contentfulBlogPost.author && data.contentfulBlogPost.author.length > 0
    ? data.contentfulBlogPost.author[0].email : null;

  return (
    <Layout
    >
      <SEO title={title} />
      {/* <SEO title={`Blog | ${category[0].name}`} /> */}
      <div>
        <ArticleHeader imageExists={!!imageData}>
          <Container className="position-relative">
            {showCategory && (
              <div className=" px-3 px-lg-0">
                <Link
                  to={`/blog/${category[0].slug}/`}
                  className="category text-uppercase"
                >
                  {category[0].name}
                </Link>
              </div>
            )}

            <h1 className="title my-5 px-3 px-lg-0">{title}</h1>
            <div className="post-details mb-5 d-block d-md-flex justify-content-between align-items-center">
              <div className={`created-at px-3 px-lg-0`}>
                <span>
                  {`${publishedOn} . 4 minutes`}
                </span>
              </div>
              {authorImage && (
              <div className="header-author-details px-3 px-lg-0 text-white mt-3 mt-md-0">
                <a href="#aboutTheAuthor">
                  <Image
                      fluid={{ ...authorImage, aspectRatio: 1 / 1}}
                      width={100}
                      className="author-profile-image"
                      alt={authorImageAlt}
                  />
                </a>
                <a href="#aboutTheAuthor" className="text-white">{authorName}</a>
              </div>)}
            </div>
            {imageData && (
              <Image
                fluid={{ ...imageData, aspectRatio: 21 / 9 }}
                className="blog-header-image position-relative w-100"
                alt={imageDataAlt}
              />
            )}
          </Container>
        </ArticleHeader>
        <Container className="my-5 px-3 px-lg-0">
          {/* <ShareBar pageUrl={pageUrl} title={title} /> */}
          <ArticleBody className="mb-5">{document}</ArticleBody>
          {authorImage && (
            <AuthorDetails>
            <hr />
            <h2 id="aboutTheAuthor" className="mt-4">About the author</h2>
              <div className="author-details p-4 mt-4 mb-5">
                <Image
                    fluid={{ ...authorImage, aspectRatio: 1 / 1}}
                    width={300}
                    className="author-profile-image"
                    alt={authorImageAlt}
                />
                <div className="author-bio">
                  <div className="author-name font-weight-bold ambit mb-3">{authorName}</div>
                  <p>{authorDescription}</p>  
                  <p className="p-0 m-0"><a href={"mailto:" + authorEmail}>{authorEmail}</a></p>  
                </div>
              </div>
            </AuthorDetails>
          )}
          {/* <ShareBar pageUrl={pageUrl} title={title} className="my-5" /> */}
          <section className="pt-5 border-top d-flex flex-column align-items-center">
            <Row>
              {latestPosts.map((post, index) => (
                post.node.category && ( 
                  <Col key={post.node.id}>
                    <PostPreview post={post} showCategory={true} />
                  </Col>
              )))}
            </Row>
            <div>
              <ApButton variant="green" className="pl-4 pr-4" onClick={(e) => navigateTo(e, "blog")}>
                See All
              </ApButton>
            </div>
          </section>
        </Container>
        <SignUpForNewsLetter />
      </div>
    </Layout>
  )
}

export default BlogArticle

export const query = graphql`
  query Getpost($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      publishedOn(formatString: "DD MMM, YYYY")
      category {
        name
        slug
      }
      childContentfulBlogPostArticleRichTextNode {
        json
      }
      previewImage {
        fluid(quality: 90, maxWidth: 1170) {
          ...GatsbyContentfulFluid
        }
        title
        updatedAt
      }
      author {
        name
        profilePicture {
          fluid(quality: 75, maxHeight: 250, maxWidth: 250) {
            ...GatsbyContentfulFluid
          }
          title
        }
        description
        email
      }
    }
    allContentfulBlogPost(
      limit: 4
      sort: { fields: publishedOn, order: DESC }
      filter: { category: { elemMatch: { slug: { nin: "internal" } } } }
    ) {
      edges {
        node {
          id
          publishedOn(formatString: "d MMM, yyyy")
          previewImage {
            fluid {
              src
            }
            title
          }
          category {
            name
            slug
          }
          title
          slug
          externalLink
        }
      }
    }
  }
`
