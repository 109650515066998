import React from "react"
import { StyledPostPreview } from "./PostPreview.style"
import Img from "gatsby-image"
import { Link } from "gatsby"

function PostPreview({ post, showCategory }) {
  const { category, publishedOn, title, previewImage, id, slug, externalLink } = post.node;
  const blogLink = externalLink || `/blog/${slug}/`;
  const previewImageAlt = previewImage && previewImage.title ? previewImage.title : title;
  return (
    <StyledPostPreview>
      <div className="post-preview d-flex flex-column pb-5">
        <Link to={blogLink}>
          <Img fluid={previewImage && previewImage.fluid} className="w-100 preview-img" alt={previewImageAlt}/>
        </Link>
        <span className="mt-4 mb-1 secondary-blue-2-text">
          {showCategory && (
            <Link to={`/blog/${category[0].slug}/`}>
              <span className="mr-3 font-weight-bold secondary-blue-2-text">
                {category[0].name}
              </span>
            </Link>
          )}
          <small>{publishedOn} &nbsp;&bull;&nbsp; 4 minutes</small>
        </span>
        <Link to={blogLink}>
          <h5 className="font-weight-bold blog-title primary-blue-text nunito ">
            {title}
          </h5>
        </Link>
      </div>
    </StyledPostPreview>
  )
}

export default PostPreview
